.appName {
  padding-left: 15px;
  max-height: 50px;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  .appName {
    padding-left: 10px;
    max-height: 30px;
    margin-top: 15px;
  }
}
.logo {
  max-width: 80px;
  align-self: center;
}
@media only screen and (max-width: 768px) {
  .logo {
    max-width: 60px;
  }
}
.ava {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
