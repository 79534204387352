.root {
  display: flex;
  max-width: 980px;
  margin: auto;
  position: relative;
  align-items: center;
}
.container {
  padding: 10px 0;
}
@media only screen and (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
}
.secondaryBg {
  background-color: #f8f8f8;
}
.large {
  padding: 40px 100px;
}
@media only screen and (max-width: 768px) {
  .large {
    padding: 30px 40px;
  }
}
.heading {
  font-weight: bold;
  font-size: 2em;
}
.content {
  width: 40%;
}
@media only screen and (max-width: 768px) {
  .content {
    width: 100%;
  }
}
.imageContainer {
  width: 60%;
  text-align: right;
}
@media only screen and (max-width: 768px) {
  .imageContainer {
    display: none;
  }
}
.image {
  align-self: center;
  max-height: 300px;
  max-width: 100%;
}
.reverse .imageContainer {
  text-align: left;
  margin-right: 40px;
}
