.button {
  display: inline-block;
  border: 1px solid #77b373;
  border-radius: 4px;
  padding: 8px 32px;
  text-decoration: none !important;
}
.solid {
  background-color: #77b373;
  color: white;
}
.ghost {
  color: #77b373;
}
