.slackTitle {
  position: relative;
}
.slackIcon {
  position: absolute;
  top: -10px;
  width: 60px;
  right: -55px;
}
@media only screen and (max-width: 768px) {
  .button {
    display: block;
    text-align: center;
  }
}
.email {
  color: #77b373;
  font-weight: bold;
  text-decoration: underline;
}
